var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    {
      staticClass: "margin-top-25",
      staticStyle: { padding: "20px" },
      attrs: { gutter: 20 },
    },
    [
      _c(
        "el-form",
        [
          _vm.data
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Minutos bloqueio" } },
                        [
                          _c("el-input-number", {
                            attrs: { size: "mini" },
                            model: {
                              value: _vm.data.MinutosBloqueio,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "MinutosBloqueio", $$v)
                              },
                              expression: "data.MinutosBloqueio",
                            },
                          }),
                          _c("p", { staticClass: "p-info-desc" }, [
                            _vm._v(
                              "Tempo em minutos que um proxy ficará bloqueado quando não atender a taxa de acerto "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Minutos listagem" } },
                        [
                          _c("el-input-number", {
                            attrs: { size: "mini" },
                            model: {
                              value: _vm.data.MinutosListagem,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "MinutosListagem", $$v)
                              },
                              expression: "data.MinutosListagem",
                            },
                          }),
                          _c("p", { staticClass: "p-info-desc" }, [
                            _vm._v(
                              "Tempo de listagem no banco para montar a taxa de acerto"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Percentual erro máximo" } },
                        [
                          _c("el-input-number", {
                            attrs: { size: "mini" },
                            on: { change: _vm.emitirErroMax },
                            model: {
                              value: _vm.data.PercentualErroMaximo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "PercentualErroMaximo", $$v)
                              },
                              expression: "data.PercentualErroMaximo",
                            },
                          }),
                          _c("p", { staticClass: "p-info-desc" }, [
                            _vm._v("Percentual máximo de erro para bloquear"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }