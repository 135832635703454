import { render, staticRenderFns } from "./ModalLogarComo.vue?vue&type=template&id=147e6428&scoped=true"
import script from "./ModalLogarComo.vue?vue&type=script&lang=js"
export * from "./ModalLogarComo.vue?vue&type=script&lang=js"
import style0 from "./ModalLogarComo.vue?vue&type=style&index=0&id=147e6428&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147e6428",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/azp/_work/2/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('147e6428')) {
      api.createRecord('147e6428', component.options)
    } else {
      api.reload('147e6428', component.options)
    }
    module.hot.accept("./ModalLogarComo.vue?vue&type=template&id=147e6428&scoped=true", function () {
      api.rerender('147e6428', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/CabecalhoLayout/ModalLogarComo.vue"
export default component.exports