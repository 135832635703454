import { render, staticRenderFns } from "./LoadingPage.vue?vue&type=template&id=06ac0514&scoped=true"
import script from "./LoadingPage.vue?vue&type=script&lang=js"
export * from "./LoadingPage.vue?vue&type=script&lang=js"
import style0 from "./LoadingPage.vue?vue&type=style&index=0&id=06ac0514&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ac0514",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/azp/_work/2/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06ac0514')) {
      api.createRecord('06ac0514', component.options)
    } else {
      api.reload('06ac0514', component.options)
    }
    module.hot.accept("./LoadingPage.vue?vue&type=template&id=06ac0514&scoped=true", function () {
      api.rerender('06ac0514', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/LoadingPage.vue"
export default component.exports