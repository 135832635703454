var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        {
          staticClass: "icon-top icon-top-a dropdown-user",
          attrs: { trigger: "click", placement: "bottom" },
          on: { command: _vm.AcaoUsuario },
        },
        [
          _c("span", { staticClass: "el-dropdown-link" }, [
            _vm.UsuarioLogado
              ? _c(
                  "span",
                  {
                    staticClass: "show-starting-1200 hideOnlyMobile",
                    staticStyle: { display: "flex", "align-items": "center" },
                  },
                  [
                    _c("div", [
                      _vm.urlFile
                        ? _c(
                            "div",
                            {
                              staticClass: "user-info-2",
                              attrs: { md: 4, xs: 24 },
                            },
                            [
                              _c("img", {
                                staticClass: "avatar-2",
                                attrs: { src: _vm.urlFile, alt: "User Image" },
                                on: { error: _vm.handleImageError },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "user-info",
                              style: `--avatar-color: ${_vm.avatarColor}`,
                            },
                            [
                              _c("span", { staticClass: "avatar" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getAvatarInitials(
                                      _vm.UsuarioLogado.Nome
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                    ]),
                    _c("div", { staticStyle: { "text-align": "left" } }, [
                      _vm.UsuarioLogado && _vm.UsuarioLogado.Nome
                        ? _c("div", { staticClass: "spn-nome-usuario" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.UsuarioLogado.Nome.split(" ")[0]) +
                                " "
                            ),
                            _c("span", { staticClass: "el-dropdown-icon" }, [
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.logadoComo && _vm.UsuarioLogado.IdPermissao === 1
                        ? _c("div", { staticClass: "nome-logado-como" }, [
                            _vm._v(" " + _vm._s(_vm.logadoComo.Nome) + " "),
                          ])
                        : _vm._e(),
                      _vm.ParceiroAtual && !_vm.logadoComo
                        ? _c("span", { staticClass: "nome-logado-como" }, [
                            _vm._v(_vm._s(_vm.ParceiroAtual.Nome)),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "span",
              {
                staticClass:
                  "el-dropdown-link hide-starting-992 icon-top icon-top-a",
              },
              [_c("i", { staticClass: "el-icon-user-solid" })]
            ),
          ]),
          _vm.UsuarioLogado
            ? _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm.logadoComo && _vm.UsuarioLogado.IdPermissao === 1
                    ? _c("el-dropdown-item", { attrs: { disabled: "" } }, [
                        _vm.logadoComo && _vm.UsuarioLogado.IdPermissao === 1
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "hide-starting-1200 spn-logado-como text-uppercase",
                              },
                              [_vm._v(" " + _vm._s(_vm.logadoComo.Nome) + " ")]
                            )
                          : _vm.ParceiroAtual
                          ? _c("span", { staticClass: "spn-nome-usuario" }, [
                              _c("em", [
                                _vm._v(_vm._s(_vm.UsuarioLogado.Nome) + " "),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "logado-na",
                      attrs: { command: "goToUser" },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _vm.urlFile
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "user-info-3",
                                    attrs: { md: 4, xs: 24 },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "avatar-3",
                                      attrs: {
                                        src: _vm.urlFile,
                                        alt: "User Image",
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "user-info-3 dropdown-user dropdown-user-2",
                                    style: `--avatar-color: ${_vm.avatarColor}`,
                                  },
                                  [
                                    _c("span", { staticClass: "avatar-3" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAvatarInitials(
                                            _vm.UsuarioLogado.Nome
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                          ]),
                          _c("el-col", { attrs: { span: 17 } }, [
                            _vm.UsuarioLogado && _vm.UsuarioLogado.Nome
                              ? _c("span", { attrs: { size: "small" } }, [
                                  _vm._v(
                                    "Olá, " +
                                      _vm._s(
                                        _vm.UsuarioLogado.Nome.split(" ")[0]
                                      ) +
                                      "!"
                                  ),
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm.ParceiroAtual
                              ? _c("b", { staticClass: "b-nome-empresa" }, [
                                  _vm._v(_vm._s(_vm.ParceiroAtual.Nome)),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.temPermissao
                    ? _c(
                        "el-dropdown-item",
                        {
                          staticClass: "hideOnlyMobile hideOnIpad",
                          attrs: { divided: "", command: "verPlano" },
                        },
                        [
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(" Meu plano "),
                        ]
                      )
                    : _vm._e(),
                  _vm.UsuarioLogado &&
                  (_vm.UsuarioLogado.IdPermissao === 1 || _vm.PossuiRede)
                    ? _c(
                        "el-dropdown-item",
                        { attrs: { command: "trocarConta" } },
                        [
                          _c("i", { staticClass: "el-icon-guide" }),
                          _vm._v(" Acessar outra conta "),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { command: "convidarAmigos" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.convidarAmigos.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-share" }),
                      _vm._v(" Convidar amigos "),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "logout", divided: "" } },
                    [
                      _c("i", { staticClass: "el-icon-switch-button" }),
                      _vm._v(" Sair "),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("modal-logar-como", { ref: "mdLogarComo" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }