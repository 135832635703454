var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading || _vm.deleting,
          expression: "loading || deleting",
        },
      ],
    },
    [
      _vm.builded && !_vm.loading
        ? _c(
            "el-table",
            {
              attrs: {
                data: _vm.filteredProxies,
                "show-summary": _vm.isEdicaoProxyPorCia,
                "summary-method": _vm.getSummaries,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "Id", label: "#", width: "50" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "Name",
                  label: "Proxy",
                  width: !_vm.isEdicaoProxyPorCia ? "200" : "",
                },
              }),
              !_vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: { prop: "Config", label: "Config" },
                  })
                : _vm._e(),
              _vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: {
                      prop: "PercentSucess",
                      label: "Taxas",
                      align: "center",
                      width: "auto",
                      "controls-position": "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("grafico-acerto", {
                                attrs: {
                                  "id-cia": _vm.idCia,
                                  proxy: _vm.GetProxy(scope.row.Id),
                                  "erro-max": _vm.erroMax,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      392770951
                    ),
                  })
                : _vm._e(),
              _vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: { prop: "Rpm", label: "RPM", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.GetProp(scope.row.Id, "Rpm")) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1920265177
                    ),
                  })
                : _vm._e(),
              _vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: {
                      prop: "AvarageTime",
                      label: "Tempo médio",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.GetTempoMedio(scope.row.Id)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3220071534
                    ),
                  })
                : _vm._e(),
              _vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: {
                      prop: "ProxyCia[0].Probability",
                      align: "center",
                      label: "Tráfego %",
                      width: "200",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              !_vm.intervalOn &&
                              scope.row.ProxyCia &&
                              scope.row.ProxyCia.length
                                ? _c("el-input-number", {
                                    staticClass: "el-input-number-sm",
                                    attrs: {
                                      size: "mini",
                                      "controls-position": "right",
                                      disabled: _vm.intervalOn,
                                      min: 0,
                                      max: 100,
                                      step: 1,
                                    },
                                    model: {
                                      value: scope.row.ProxyCia[0].Probability,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row.ProxyCia[0],
                                          "Probability",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.ProxyCia[0].Probability",
                                    },
                                  })
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.ProxyCia[0].Probability
                                      ) + "%"
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3222048697
                    ),
                  })
                : _vm._e(),
              !_vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: { label: "Ativo em", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    placeholder: "Select",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setAtivos(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.ativoEm,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "ativoEm", $$v)
                                    },
                                    expression: "scope.row.ativoEm",
                                  },
                                },
                                _vm._l(_vm.cias, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2024843281
                    ),
                  })
                : _vm._e(),
              !_vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: {
                      prop: "IsSubnet",
                      label: "Subnet",
                      width: "80",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.IsSubnet ? "Sim" : "Não") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      159421932
                    ),
                  })
                : _vm._e(),
              !_vm.isEdicaoProxyPorCia
                ? _c("el-table-column", {
                    attrs: { width: "150", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-button", {
                                attrs: {
                                  type: "info",
                                  plain: "",
                                  icon: "el-icon-edit",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Selecionar(scope.row)
                                  },
                                },
                              }),
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  plain: "",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletar(scope.row)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4003867052
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.Proxies.length
        ? _c("edit-proxy", { ref: "editProxy", attrs: { proxy: _vm.Selected } })
        : _vm._e(),
      _vm.isEdicaoProxyPorCia
        ? _c("proxy-manager-config", {
            ref: "pManagerConfig",
            attrs: { "id-cia": _vm.idCia },
            on: {
              change: function ($event) {
                _vm.erroMax = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }