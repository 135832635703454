var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.proxy
      ? _c(
          "div",
          {
            staticClass: "percent-bar-container",
            class: { blocked: _vm.proxy.Blocked },
          },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.PercentualSucesso + "%",
                  placement: "top",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "percent-bar success",
                    style: { width: _vm.PercentualSucesso + "%" },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.PercentualSucesso >= 20,
                            expression: "PercentualSucesso >= 20",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.PercentualSucesso) + "%")]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                attrs: { content: _vm.PercentualErro + "%", placement: "top" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "percent-bar error",
                    style: { width: _vm.PercentualErro + "%" },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.PercentualErro >= 20,
                            expression: "PercentualErro >= 20",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.PercentualErro) + "%")]
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      : _c("div", [
          _c("p", { staticClass: "info-no-data" }, [_vm._v("Sem dados")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }