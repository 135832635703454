import { render, staticRenderFns } from "./EditProxy.vue?vue&type=template&id=ece39efa&scoped=true"
import script from "./EditProxy.vue?vue&type=script&lang=js"
export * from "./EditProxy.vue?vue&type=script&lang=js"
import style0 from "./EditProxy.vue?vue&type=style&index=0&id=ece39efa&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ece39efa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/azp/_work/2/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ece39efa')) {
      api.createRecord('ece39efa', component.options)
    } else {
      api.reload('ece39efa', component.options)
    }
    module.hot.accept("./EditProxy.vue?vue&type=template&id=ece39efa&scoped=true", function () {
      api.rerender('ece39efa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Proxy/EditProxy.vue"
export default component.exports